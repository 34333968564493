<template>
  <b-card>
    <b-row v-if="isPrint">
      <b-col
        cols="12"
        style="align-self: center; text-align: end;"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="primary"
          @click="printBAM"
        >
          Print Berita Acara
        </b-button>
      </b-col>
    </b-row>
    <info @showData="showData"/>
  </b-card>
</template>

<script>

import {BRow, BCol, BCard, BTabs, BTab, BButton} from 'bootstrap-vue'

import Info from './include/Info.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    Info,
  },
  data(){
    return {
      isPrint:false
    }
  },
  methods:{
    showData(result){
      if(result.status == 'received'){
        this.isPrint = true
      }
    },
    printBAM(){
      this.$http.get('vehicle-mutation/'+this.$route.params.id+'/bamk/print').then(res=>{
        window.open(res.data.filename)
      })
    }
  }
}
</script>
