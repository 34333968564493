<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :col_single="false"
      :isedit="false"
      :isdelete="false"
      :isAction="status == 'requested'"
      @readData="showData"
    >
      <template v-slot:buttonaction>
        <b-button
          v-if="status == 'requested'"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="confirmAction('approve')"
        >
          
          <span>Setujui</span>
        </b-button>
        <b-button
          v-if="status == 'requested'"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="confirmAction('reject')"
        >
          
          <span>Tolak</span>
        </b-button>
      </template>
    </base-info>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <h3 class="font-weight-bolder">
          Daftar Kendaraan
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fieldsTable"
          />

        </b-overlay>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay,
    BButton
  },

  data() {
    return {
      dataurl:"/vehicle-mutation",
      baseroute:"mutation",
      fields: [
        { key: 'number', label: 'Kode', bold:true},
        { key: 'author.name', label: 'Pemesanan' },
        { key: 'origin.name', label: 'Cabang Asal', bold:true },
        { key: 'author.name', label: 'Pemberi Persetujuan' },
        { key: 'region.name', label: 'Cabang Tujuan' },
        { key: 'received_date', label: 'Tgl Persetujuan' , type:'date'},
        { key: 'area.name', label: 'Area' },
        { key: 'request_date', label: 'Tgl Permintaan' , type:'date'},
        { key: 'ulp.name', label: 'ULP' },
        { key: 'used_date', label: 'Tgl Kebutuhan' , type:'date'},
        { key: 'project.name', label: 'Project' },
        { key: 'note', label: 'Keterangan' },
        // { key: 'transmission', label: 'Persetujuan' },
      ],
      fieldsTable: [
        { key: 'number', label: 'Nopol'},
        // { key: 'code', label: 'Kode'},
        { key: 'variant.model', label: 'Varian'},
        { key: 'initial_km', label: 'KM Tempuh'},
        { key: 'contact.name', label: 'Kepemilikan'},
        { key: 'frame_number', label: 'Nomor Rangka'},
        { key: 'machine_number', label: 'Nomor Mesin'},
        { key: 'color', label: 'Warna'},
      ],
      items:[],
      datalabel:[],
      dataseries:[],
      loading:false,
      status:null
    }
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.$refs.baseinfo.fetchData()
      }
    }
  },
  methods: {
    showData(result){
      this.$emit('showData', result)
      this.status = result.status
      this.items = result.vehicles
    },
    confirmAction(action){
      const id = this.$route.params.id

      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/'+action)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    }
  }
}
</script>
